// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-previewpage-tsx": () => import("./../src/pages/previewpage.tsx" /* webpackChunkName: "component---src-pages-previewpage-tsx" */),
  "component---src-templates-article-page-tsx": () => import("./../src/templates/article_page.tsx" /* webpackChunkName: "component---src-templates-article-page-tsx" */),
  "component---src-templates-event-page-tsx": () => import("./../src/templates/event_page.tsx" /* webpackChunkName: "component---src-templates-event-page-tsx" */),
  "component---src-templates-job-page-tsx": () => import("./../src/templates/job_page.tsx" /* webpackChunkName: "component---src-templates-job-page-tsx" */),
  "component---src-templates-page-page-tsx": () => import("./../src/templates/page_page.tsx" /* webpackChunkName: "component---src-templates-page-page-tsx" */),
  "component---src-templates-press-page-tsx": () => import("./../src/templates/press_page.tsx" /* webpackChunkName: "component---src-templates-press-page-tsx" */),
  "component---src-templates-researchproject-page-tsx": () => import("./../src/templates/researchproject_page.tsx" /* webpackChunkName: "component---src-templates-researchproject-page-tsx" */)
}

