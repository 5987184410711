//Source: https://www.gatsbyjs.org/packages/gatsby-background-image/?=background#important
exports.onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (typeof window.IntersectionObserver === `undefined`) {
        import(`intersection-observer`)
        console.log(`# IntersectionObserver is polyfilled!`)
    }
}

exports.shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
    if (prevRouterProps && routerProps) {
        if (prevRouterProps.location.pathname === routerProps.location.pathname) {
            return !routerProps.location.href.includes('scrolllock')
        }
    }
    return true
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
    if (location && location.state)
        location.state.referrer = prevLocation ? prevLocation.pathname : null
}
